import {useLoaderData} from '@remix-run/react';
import {json, type LoaderArgs} from '@shopify/remix-oxygen';
import groq from 'groq';
import invariant from 'tiny-invariant';
import {Sections} from '~/components/layout';
import {sanityClient} from '~/lib/sanity';
import {getShopifyProducts} from '~/lib/sanityShopify';
import {SECTIONS} from '~/queries/sanity/sections';
import {SEO} from '~/queries/sanity/seo';
import {SanityPage} from '~/types';
import {CACHE_LONG, routeHeaders} from '~/data/cache';
import {seoPayload} from '~/lib/seo.server';

export const headers = routeHeaders;

export async function loader({request, params, context}: LoaderArgs) {
  invariant(params.pageHandle, 'Missing page handle');

  const page = await sanityClient.fetch<SanityPage>(PAGE_SANITY_QUERY, {
    slug: params.pageHandle,
  });
  if (!page) {
    throw new Response(null, {status: 404});
  }

  const {sections, seo: sanitySeo} = page;

  const shopifyProducts = await getShopifyProducts(
    sections,
    context.storefront,
  );

  const seo = seoPayload.page({sanitySeo, url: request.url});

  return json(
    {
      seo,
      sections,
      shopifyProducts,
    },
    {
      headers: {
        'Cache-Control': CACHE_LONG,
      },
    },
  );
}

export default function Page() {
  const {sections, shopifyProducts} = useLoaderData<typeof loader>();

  return (
    <>
      {sections && (
        <Sections sections={sections} shopifyProducts={shopifyProducts} />
      )}
    </>
  );
}

const PAGE_SANITY_QUERY = groq`
  *[_type == 'page' && slug.current == $slug][0]{
    sections[] {
      ${SECTIONS}
    },
    ${SEO}
  }
`;
